import rewrites from "@/cms/Rewrites/data.json";
import rawData from "@/cms/Rewrites/rawData.json";
import { ParsedUrlQuery } from "querystring";

export function pageLinkProps(locale: string, type: keyof typeof rewrites, id: string, query?: ParsedUrlQuery) {
  const as = (rewrites as Record<string, Record<string, Record<string, string>>>)[type][id]?.[locale];
  if (!as) {
    return {
      href: { pathname: "/404" },
      as: `/${locale}/404`,
    };
  }

  /*
   * If the page requires a tweak, locate it through the 'rawData.json' file.
   */
  const tweak = rawData.rewrites.find(
    ([_source, _locale, _type, _id, _tweak]) => locale === _locale && type === _type && id === _id,
  )?.[4];

  return {
    locale,
    href: {
      pathname: `/_render/${type}/[id]${tweak ? `/${tweak}` : ""}`,
      query: { ...query, id },
    },
    as: (() => {
      const url = new URL(as, "https://cyp.ch");

      if (query) {
        for (const [k, v] of Object.entries(query)) {
          if (k !== "id") {
            url.searchParams.set(k, v as string);
          }
        }
      }

      return url.toString().replace(/^https:\/\/cyp.ch/, "");
    })(),
  };
}
